.future {
    background-color: #BFE2B2;
}

.current {
    background-color: #E2E0B2;
}

.last {
    background-color: #E2C9B2;
}

.user-courses-container {
    display: grid;
    grid-template-columns: 3fr 5fr 2fr;
    justify-content: space-around;
    border: 0.5px solid #5A7651;
    align-items: center;
    padding: 0.1em;
}

.course-category {
    padding: 0.3em 0.5em;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
}

.course-container {
    display: grid;
    justify-content: space-between;
}

.jawab-button {
    padding: 5px 15px 5px 15px;
    color: #fff;
    font-size: 10px;
    font-weight: 700;
    font-family: 'Inter';
    width: 82px;
    text-align: center;
    cursor: pointer
}

.danger-btn {
    background-color: #6B3B26;
    cursor: pointer
}

.success-btn {
    background-color: #315A23;
    cursor: pointer
}

.hide {
    display: none;
}

.cursor-block {
    cursor: not-allowed;
}