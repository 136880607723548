  @media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) { 

    .largeContainer{
        display: block;
    }

    .smallScreen{
        display: none;
    }

}