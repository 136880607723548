
    @media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {

    .smallScreen{
        display: flex;
        padding: 10px;
        background-color: rgb(0, 33, 71);
      
    }
    .navbar-brand {
        color: #fff;
    }

    .largeContainer{
        display: none;
    }

}