@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (-webkit-min-device-pixel-ratio: 2) {

    .hide-in-big{
        display: block;
    }

}

@media screen 
and (min-device-width: 1200px) 
and (max-device-width: 1600px) 
and (-webkit-min-device-pixel-ratio: 1) { 

    .hide-in-big{
        display: none;
    }

}

.toggle-element{
    border-radius: 5px;
    text-decoration: none;
    width: 100%;
    background-color: #fff;
    border: none;
}